<template>
  <div
    class="PSInput"
    :class="[
      size ? `PSInput--size-${size}` : '',
      `PSInput--mode-${mode}`,
      {
        'PSInput--required': !label && required,
        'PSInput--readonly': readonly,
        'PSInput--disabled': disabled,
        'PSInput--rounded': isRounded,
      },
    ]"
    :style="[
      {
        width: width,
      },
    ]"
  >
    <div
      v-if="label"
      class="PSInput__label"
      :class="{
        'PSInput__label--required': required,
      }"
    >
      {{ label }}
    </div>

    <!-- TEXTAREA -->
    <template v-if="type === EInputTypes.Textarea">
      <textarea
        ref="inputElement"
        :readonly="readonly"
        class="PSInput__textarea"
        :class="[
          theme ? `PSInput--theme-${theme}` : '',
          _noTransition ? `PSInput--no-transition` : '',
        ]"
        :style="{
          '--height': heightResult,
          '--max-height': maxHeight,
          // height: getHeight,
          width: width,
        }"
        :placeholder="placeholder"
        :value="modelValueFormat"
        @input="onInput"
        @change="(event) => onChange(event.target.value)"
      />
      <!-- второй скрытый для автовысоты, он моментально меняет высоту, и дает значение новой высоты, которую анимируем на основное поле -->
      <textarea
        v-if="autoHeight"
        ref="inputElement2"
        tabindex="-1"
        class="PSInput__textarea PSInput__textarea--hidden"
        :style="{
          width: calcWidthForHiddenTextarea,
        }"
        :placeholder="placeholder"
        :value="modelValueFormat"
      />
    </template>

    <!-- TIME -->
    <template v-else-if="type === EInputTypes.Time">
      <PSSelect
        :readonly="readonly"
        :model-value="modelValueFormat"
        :options="getTimeValues"
        placeholder="..."
        align-center
        hide-arrow
        width="90px"
        @update:model-value="emit('update:modelValue', $event)"
      />
    </template>

    <!-- PHONE -->
    <MaskInput
      v-else-if="type === EInputTypes.Tel"
      ref="inputElement"
      :readonly="readonly"
      :autocomplete="autocompleteOff ? 'off' : 'on'"
      mask="+7 ### - ### ## ##"
      class="PSInput__input"
      :class="[theme ? `PSInput--theme-${theme}` : '']"
      :type="type"
      :value="modelValueFormat"
      :placeholder="placeholder"
      :style="{ width: width }"
      @change="(event) => onChange(event.target.value)"
      @input="onInput"
      @keypress="preventNumericInput($event)"
    />

    <!-- TEXT -->
    <input
      v-else
      ref="inputElement"
      :readonly="readonly"
      :autocomplete="autocompleteOff ? 'off' : 'on'"
      class="PSInput__input"
      :class="[
        align ? `text-${align}` : '',
        theme ? `PSInput--theme-${theme}` : '',
        {
          'PSInput--price': format === 'price',
          'PSInput--number': format === 'number',
        },
      ]"
      :type="type"
      :value="modelValueFormat"
      :placeholder="placeholder"
      :style="{ width: width }"
      @change="(event) => onChange(event.target.value)"
      @input="onInput"
      @keypress="preventNumericInput($event)"
      @focus="onFocus"
      @blur="onBlur"
    >
  </div>
</template>

<script setup lang="ts">
import { MaskInput, } from 'vue-3-mask';
import {
  EInputTypes,
  EInputSizes,
  EInputThemes,
  EInputMode,
} from './PSInput.enum';
import PSSelect from './PSSelect.vue';
import unFormatPrice from '@/utils/unFormatPrice';

const inputElement = ref<HTMLElement>();
const inputElement2 = ref<HTMLElement>();

const getTimeValues = computed(() => {
  let times: string[] = [];

  for (let hour = 0; hour < 24; hour++) {
    times.push(String(hour) + ':00');
    times.push(String(hour) + ':15');
    times.push(String(hour) + ':30');
    times.push(String(hour) + ':45');
  }

  return times.map((time) => {
    return {
      label: time,
      value: time,
    };
  });
});

const props = withDefaults(
  defineProps<{
    type: EInputTypes;
    modelValue: string | number;
    placeholder?: string;
    width?: string;
    height?: string;
    maxHeight?: string;
    label?: string;
    autoHeight?: boolean;
    theme?: EInputThemes;
    format?: 'price' | 'number';
    noTransition?: boolean;
    size?: EInputSizes;
    required?: boolean;
    mode?: EInputMode;
    autocompleteOff?: boolean;
    readonly?: boolean;
    align?: 'left' | 'center' | 'right';
    disabled?: boolean;
    isRounded?: boolean;
  }>(),
  {
    type: EInputTypes.Text,
    mode: EInputMode.Default,
  }
);

const _noTransition = ref(true);

onMounted(async () => {
  await nextTick();
  _noTransition.value = props.noTransition;
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void;
  (e: 'input', value: string | number): void;
}>();

const calcWidthForHiddenTextarea = computed(() => {
  if (props.autoHeight && inputElement.value) {
    return inputElement.value.clientWidth + 'px';
  } else {
    return 0 + 'px';
  }
});

const heightResult = ref('0px');
const calcHeight = () => {
  if (props.autoHeight) {
    if (!inputElement.value) {
      props.height || '120px';
    }

    setTimeout(() => {
      if (
        inputElement.value &&
        inputElement2.value &&
        inputElement2.value?.scrollHeight
      ) {
        const propHeightNumber = Number(props.height?.replaceAll('px', ''));

        if (!propHeightNumber) {
          heightResult.value = props.height || '120px';
        } else if (inputElement2.value.scrollHeight > propHeightNumber) {
          heightResult.value = inputElement2.value.scrollHeight + 'px';
        } else {
          heightResult.value = props.height || '120px';
        }
      }
    });
  } else {
    if (props.height) {
      heightResult.value = props.height;
    }
  }
};

onMounted(() => {
  calcHeight();
});

const isFocus = ref(false);

const modelValueFormat = computed(() => {
  if (
    isFocus.value &&
    !props.readonly &&
    !props.disabled &&
    unFormatPrice(props.modelValue) === 0
  ) {
    return '';
  }

  if (props.format === 'price') {
    return unFormatPrice(props.modelValue).toLocaleString('ru', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else if (props.format === 'number') {
    return unFormatPrice(props.modelValue).toLocaleString('ru');
  } else {
    return props.modelValue;
  }
});

const preventNumericInput = ($event) => {
  if (props.format === 'price' || props.format === 'number') {
    var keyCode = $event.keyCode ? $event.keyCode : $event.which;
    console.log('🚀 ~ preventNumericInput ~ keyCode:', keyCode);

    /**
     * 48-57 - цифры
     * 44 - запятая
     * 46 - точка
     */
    if (!((keyCode > 47 && keyCode < 58) || keyCode === 44 || keyCode === 46)) {
      $event.preventDefault();
    }
  }
};

function formatValue(value) {
  if (props.format === 'price') {
    emit('update:modelValue', Number(unFormatPrice(value)).toFixed(2));
  } else if (props.format === 'number') {
    emit('update:modelValue', Number(unFormatPrice(value)));
  } else {
    emit('update:modelValue', value);
  }
}

const onChange = (value) => {
  formatValue(value);

  if (props.autoHeight) {
    calcHeight();
  }
};
const onInput = (event) => {
  emit('input', event.target.value);

  if (props.autoHeight) {
    calcHeight();
  }
};

function onFocus() {
  isFocus.value = true;
}

function onBlur() {
  isFocus.value = false;
}
</script>

<style lang="scss" scoped>
.PSInput {
  $s: &;
  line-height: 0;
  position: relative;

  &--required {
    &::before {
      position: absolute;
      content: '*';
      width: 4px;
      height: 4px;
      left: -10px;
      top: 10px;
      color: $red;
    }
  }
  &__label {
    color: #464646;
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.25;
    position: relative;
    &--required {
      &::before {
        position: absolute;
        content: '*';
        left: 0px;
        top: 0;
        color: $red;
      }
    }
  }
  &__textarea,
  &__input {
    font-family: Roboto;
    width: 100%;
    font-size: 20px;
    background-color: $light-max;
    padding: 7px 15px;
    border: calc(1px / var(--zoom)) solid transparent;
    border-radius: 6px;
    outline: none;
    caret-color: black;

    &:focus {
      border-color: $blue;
    }
    &:placeholder {
      color: #464646;
    }
  }
  &__textarea {
    color: black;
    height: var(--height);
    max-height: var(--max-height);
    transition: 0.3s;
    border: none !important;
    outline: none;
    resize: none;
    @include scroll;

    &--hidden {
      --height: 0px !important;
      transition: 0s;
      position: absolute !important;
      left: -999px;
      top: -999px;
      z-index: 8999;
      pointer-events: none;
      transform: translate(-9999px, -9999px);
      opacity: 0;
      @include scroll;
    }
  }
  &__input {
    height: 40px;
  }
  &--no-transition {
    transition: 0s !important;
  }
  &--price {
    text-align: right;
  }
  &--theme {
    &-clear {
      padding: 0;
      border: none;
      background: none;
    }
    &-white {
      border: 2px solid #c2c2c2;
      background-color: white;
      transition: $tr;

      &:focus,
      &:hover {
        border-color: $blue;
      }
    }
    &-bordered {
      border: 2px solid #c2c2c2;
      background-color: transparent;
      transition: $tr;

      &:focus,
      &:hover {
        border-color: $blue;
      }
    }
  }

  &--readonly {
    #{$s}__input {
      border-color: transparent !important;
    }
  }

  &--disabled {
    opacity: 0.6;
    #{$s}__input {
      border-color: transparent !important;
    }
  }

  &--size {
    &-big {
      .PSInput__input {
        font-size: 28px;
      }
    }
    &-lg {
      .PSInput__input {
        min-height: 56px;
        font-size: 24px;
      }
    }
  }

  &--mode {
    &-default {
    }
    &-inline {
      display: flex;
      grid-gap: 20px;
      .PSInput__label {
        width: 40%;
      }
      .PSInput__input {
        width: 60%;
      }
    }
  }

  &--rounded {
    input {
      border-radius: 20px;
    }
  }
}
</style>
