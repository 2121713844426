<template>
  <div
    ref="select"
    class="PSSelect"
    :style="{
      '--width': width,
      '--height': height,
      '--widthDropdown': widthDropdown,
    }"
  >
    <!-- {{ multipleMap }}
    {{ value }}
    {{ getLabelActive }} -->
    <div
      v-if="label"
      class="PSSelect__label"
      :class="{
        'PSSelect__label--required': required,
      }"
    >
      {{ label }}
    </div>
    <div
      class="PSSelect__select"
      :class="{
        '_show-border': isShow,
        '_hide-arrow': hideArrow,
        '_align-center': alignCenter,
        _disabled: disabled,
        _readonly: readonly,
      }"
    >
      <tippy
        interactive
        :arrow="false"
        :offset="[0, 0]"
        :max-width="width"
        :trigger="disabled || readonly ? '' : 'click'"
        :placement="'bottom'"
        :on-show="onShow"
        :on-hide="onHide"
        :theme="'dropdown'"
      >
        <div :class="{ PSSelect__value: !labelType }">
          <!-- Поиск -->
          <template v-if="search !== undefined">
            <input
              class="PSSelect__input"
              type="text"
              :value="search"
              @input="$emit('update:search', $event.target.value)"
            >
          </template>

          <!-- Выбранное значение -->
          <template
            v-if="
              getLabelActive &&
                getLabelActive.label !== undefined &&
                getLabelActive.value !== undefined
            "
          >
            <template v-if="$slots.label">
              <slot name="label" />
            </template>

            <!-- Стиль тега -->
            <TagSimple
              v-else-if="labelType === 'TagSimple'"
              width="100%"
              :text="getLabelActive.label"
            />

            <!-- Стиль статуса  -->
            <StatusLabel
              v-else-if="labelType === 'StatusLabel'"
              width="100%"
              :status="getLabelActive.value"
            />

            <!-- Стиль Default -->
            <template v-else>
              <div class="PSSelect__value-row">
                <div
                  v-if="getLabelActive.color"
                  class="PSSelect__color-marker"
                  :style="'--bg-color:' + getLabelActive.color"
                />
                <div class="PSSelect__value-text">
                  {{ getLabelActive.label }}
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <span
              v-if="multiple"
              class="PSSelect__placeholder"
            >{{
              props.resetLabel
            }}</span>
            <span
              v-else
              class="PSSelect__placeholder"
            >{{ placeholder }}</span>
          </template>

          <!-- Стрелочка -->
          <div
            v-if="!hideArrow && !readonly && !disabled"
            class="PSSelect__arrow"
          >
            <SvgIcon name="arr2-right" />
          </div>
        </div>

        <!-- Выпадающий список -->
        <template #content="{ hide }">
          <div
            class="PSSelect__dropdown"
            :style="{ '--widthDropdown': widthDropdown }"
          >
            <div
              ref="scroll"
              class="PSSelect__scroll"
            >
              <template v-if="multiple && multipleMap">
                <div
                  v-if="props.resetLabel"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: multipleMap.length === 0 }"
                  @click="reset"
                >
                  {{ props.resetLabel }}
                </div>
                <div
                  v-for="(option, optionIndex) in optionsSearch?.length
                    ? optionsSearch
                    : options"
                  :key="option.value"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: multipleMap[optionIndex] }"
                >
                  <PSCheck
                    v-model="multipleMap[optionIndex]"
                    :label="option.label"
                  />
                  <!-- @update:model-value="
                    :model-value="false"
                      (val) => toggleActive(optionIndex, val)
                    " -->
                </div>
              </template>
              <template v-else>
                <div
                  v-if="
                    props.resetLabel &&
                      modelValue !== undefined &&
                      modelValue !== ''
                  "
                  ref="scroll2"
                  class="PSSelect__item"
                  @click="reset"
                >
                  {{ props.resetLabel }}
                </div>

                <div
                  v-for="option in props.search ? optionsSearch : options"
                  :key="option.value"
                  ref="scroll2"
                  class="PSSelect__item"
                  :class="{ active: modelValue === option.value }"
                  @click="
                    setActive(option.value);
                    hide();
                  "
                >
                  <div
                    v-if="option.color"
                    class="PSSelect__color-marker"
                    :style="'--bg-color:' + option.color"
                  />
                  {{ option.label }}
                </div>
              </template>
            </div>
          </div>
        </template>
      </tippy>
    </div>
  </div>
</template>

<script setup lang="ts">
import StatusLabel from '../StatusLabel/StatusLabel.vue';
import TagSimple from '../Tags/TagSimple.vue';
import PSCheck from './PSCheck.vue';
import { PSSelectOptions, } from './PSSelect.type';

const props = withDefaults(
  defineProps<{
    modelValue: string | number | null | Array<string | number>;
    options?: PSSelectOptions;
    optionsSearch?: PSSelectOptions;
    alignCenter?: boolean;
    placeholder?: string;
    width?: string;
    height?: string;
    hideArrow?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    label?: string;
    search?: string;
    isSearchLoading?: boolean;
    dropdownAutoWidth?: boolean;
    labelType?: 'TagSimple' | 'StatusLabel';
    required?: boolean;
    multiple?: boolean;
    resetLabel?: string;
  }>(),
  {
    placeholder: 'Выберете значение',
    active: null,
  }
);

let emit = defineEmits<{
  (
    e: 'update:modelValue',
    value: string | number | Array<string | number> | undefined
  ): void;
  (e: 'update:search', value: string | number): void;
}>();
const isShow = ref(false);
const select = ref<HTMLDivElement>();
const scroll = ref();
const scroll2 = ref();
const widthDropdown = ref('');

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (value !== undefined) {
      emit('update:modelValue', value === null ? undefined : value);
    }
  },
});

const getLabelActive = computed(() => {
  if (!props.options || !props.options.length) {
    return '';
  }

  if (props.multiple) {
    let activeLabels = '';

    // Собираем строку из выделенных значений
    if (typeof value.value === 'object') {
      value.value?.forEach((t) => {
        if (props.options) {
          const option = props.options.filter((tt) => t === tt.value)[0];
          if (activeLabels !== '') {
            activeLabels += ',';
          }
          activeLabels += ' ' + option.label;
        }
      });

      return { label: activeLabels, value: value.value, };
    }
  } else {
    return props.options.filter((t) => value.value === t.value)[0];
  }
});

const setActive = (newValue) => {
  console.log('🔷 ~ file: PSSelect.vue:235 ~ setActive ~ newValue:', newValue);
  value.value = newValue;
};

const multipleMap = ref(props.options?.map((t) => false));

if (props.multiple) {
  watchEffect(() => {
    const values = multipleMap.value?.reduce((acc, item, index) => {
      if (item) {
        if (props.options) {
          acc.push(props.options[index].value);
        }
      }
      return acc;
    }, [] as Array<string | number>);

    emit('update:modelValue', values);
  });
}

watchEffect(() => {
  if (props.dropdownAutoWidth) {
    widthDropdown.value = 'auto';
  } else if (select.value && select.value.clientWidth) {
    widthDropdown.value = select.value.clientWidth + 'px';
  }
});

const onShow = async (tippyWrap) => {
  if (!(props.disabled || props.readonly)) {
    isShow.value = true;
    await nextTick();

    // здесь автоподкрутка скролла к активному элементу
    let activeIndex = 0;

    if (scroll2.value && scroll2.value[0]) {
      const h = scroll2.value[0].clientHeight;

      scroll2.value.forEach((item, ndx) => {
        if (item.classList.contains('active')) {
          activeIndex = ndx;
        }
      });

      scroll.value.scrollTop = activeIndex * h;
    }
  }
};

const onHide = (instance) => {
  instance.value?.hide();
  isShow.value = false;
};

const reset = () => {
  if (props.multiple) {
    emit('update:modelValue', []);
    if (multipleMap.value) {
      multipleMap.value = props.options?.map((t) => false);
    }
  } else {
    emit('update:modelValue', undefined);
  }
};
</script>

<style lang="scss" scoped>
.PSSelect {
  $s: &;

  position: relative;
  width: var(--width);

  &__label {
    color: #464646;
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.25;

    &--required {
      &::before {
        position: absolute;
        content: '*';
        left: 0px;
        top: 0;
        color: $red;
      }
    }
  }
  &__color-marker {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--bg-color);
  }
  &__select {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: var(--width);
    height: var(--height);

    &._disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &._readonly {
    }
    &._hide-arrow {
      #{$s}__value {
        padding-right: 15px;
      }
    }
    &._show-border {
      #{$s}__value {
        border-color: $blue;
      }
    }
    &._align-center {
      text-align: center;
    }
  }
  &__input {
    border: none !important;
    outline: none !important;
    background-color: $light-max;
    opacity: 0;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    padding: 10px - 2px 16px - 2px;
    font-size: 20px;

    &:focus {
      opacity: 1;
    }
  }
  &__value {
    font-size: 20px;
    line-height: 1;
    background-color: $light-max;
    min-height: 40px;
    height: var(--height);
    padding: 9px 15px;
    padding-right: 34px;
    border: 1px solid transparent;
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-row {
      display: flex;
      align-items: center;
    }

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__placeholder {
    color: #464646;
    font-weight: 300;
  }
  &__dropdown {
    padding: 10px 0;
    max-width: 100%;
    width: var(--widthDropdown);
  }
  &__scroll {
    max-height: 200px;
    overflow: auto;

    padding: 0 16px;

    @include scroll;
  }
  &__arrow {
    position: absolute;
    right: 16px;
    // top: 8px;
    top: 50%;

    transform: translate(0, -50%) rotate(90deg);
    :deep(svg) {
      fill: $blue;
      width: 6px;
      height: 11px;
    }
  }
  &__item {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    @include hover {
      color: $blue;
    }
    &.active {
      color: white;
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: $blue;
        left: -16px;
        right: -16px;
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.PSSelect {
  .status-label {
    padding-right: 34px;
  }
  .tag {
    padding-right: 34px;
  }
}
</style>
